<template>
  <v-container id="data-tables" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-train-car"
      inline
      :title="$t('model')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo Modelo</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="green" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Barra de búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog v-model="isSure" @click="cancel" />

      <!-- Dialogo Agregar/ Editar Modelos de Vehiculos-->
      <v-dialog max-width="500px" v-model="dialog">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <!-- Modelo -->
                <v-col cols="12">
                  <v-text-field
                    label="Modelo*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.modelo.$model"
                    :error-messages="modeloErrors"
                    @blur="$v.editedItem.modelo.$touch()"
                    @input="$v.editedItem.modelo.$reset(), (changed = true)"
                  ></v-text-field>
                </v-col>

                <!-- Marca -->
                <v-col cols="6">
                  <v-select
                    dense
                    label="Marca*"
                    hint="Seleciona Marca"
                    item-text="marca"
                    item-value="_id"
                    persistent-hint
                    required
                    return-object
                    v-model="$v.editedItem.marca.$model"
                    :items="marcas"
                    :menu-props="{ top: true, offsetY: true }"
                    @blur="$v.editedItem.marca.$touch()"
                    @input="changed = true"
                  ></v-select>
                  {{ editedItem.marcas }}
                </v-col>

                <!-- Año -->
                <v-col cols="6">
                  <v-select
                    dense
                    label="Año*"
                    hint="Seleciona Año"
                    item-text="año"
                    item-value="año"
                    persistent-hint
                    required
                    v-model="$v.editedItem.año.$model"
                    :items="años"
                    :menu-props="{ top: true, offsetY: true }"
                    @input="changed = true"
                  ></v-select>
                  {{ editedItem.años }}
                </v-col>

                <!-- Descripcion -->
                <v-col cols="12">
                  <v-text-field
                    label="Descripción*"
                    maxlength="50"
                    required
                    v-model="$v.editedItem.descripcion.$model"
                    :error-messages="descripcionErrors"
                    @blur="$v.editedItem.descripcion.$touch()"
                    @input="$v.editedItem.descripcion.$reset(), (changed = true)"
                    @keypress="isLetter($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo Obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox label="Crear otro" v-model="checkbox" :disabled="isEditing"></v-checkbox>
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text :disabled="$v.$invalid" @click="save">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Data Table de Paises  -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="modelos"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot de Acciones-->
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton Activo-->
        <template v-slot:item.activo="{ item }">
          <Status :activo="item.activo" />
        </template>

        <template v-slot:no-data>¡No hay datos para mostrar!</template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="modelos" :dataFields="fields" fileName="ModelosVehiculo" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'

export default {
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
  },
  data: () => ({
    //Variables
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    //Arrays
    defaultItem: {
      modelo: '',
      marca: '',
      año: '',
      descripcion: '',
      activo: true,
    },
    editedItem: {
      modelo: '',
      marca: '',
      año: '',
      descripcion: '',
      activo: true,
    },
    fields: {
      Modelo: 'modelo',
      Marca: 'marca.marca',
      Año: 'año',
      Descripcion: 'descripcion',
      Activo: 'activo',
    },
    //Objetos
    //Array temporal de años para el select en el formulario
    años: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
    headers: [
      { text: 'Modelo', align: 'start', sortable: false, value: 'modelo' },
      { text: 'Marca', value: 'marca.marca' },
      { text: 'Año', value: 'año' },
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    marcas: [],
    modelos: [],
    value: [],
  }),

  mixins: [validationMixin],

  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      año: { required, minLength: minLength(4), maxLength: maxLength(4) },
      descripcion: { required, minLength: minLength(3), maxLength: maxLength(50) },
      marca: { required, minLength: minLength(1), maxLength: maxLength(50) },
      modelo: { required, minLength: minLength(3), maxLength: maxLength(20) },
    },
  },

  computed: {
    // Titulo de los formularios
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Modelo De Vehículo' : 'Editar Modelo De Vehículo'
    },

    // Propiedad computada para errores en campo Modelo
    modeloErrors() {
      const errors = []
      if (!this.$v.editedItem.modelo.$dirty) return errors
      !this.$v.editedItem.modelo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.modelo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.modelo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo Descripcion
    descripcionErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion.$dirty) return errors
      !this.$v.editedItem.descripcion.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.descripcion.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo Año
    añoErrors() {
      const errors = []
      if (!this.$v.editedItem.año.$dirty) return errors
      !this.$v.editedItem.año.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.año.minLength &&
        errors.push('Este campo debe de tener un mínimo de 4 caracteres.')
      !this.$v.editedItem.año.maxLength &&
        errors.push('Este campo debe de tener un máximo de 4 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo Marca
    marcaErrors() {
      const errors = []
      if (!this.$v.editedItem.marca.$dirty) return errors
      !this.$v.editedItem.marca.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.marca.minLength &&
        errors.push('Debe ser seleccionada una marca para el vehículo.')
      !this.$v.editedItem.marca.maxLength &&
        errors.push('Debe ser seleccionada una marca para el vehículo.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    this.obtenerMarcas()
    this.obtenerModelos()
  },

  methods: {
    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de modelos de vehiculos
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * modelos de vehiculos
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de modelos de vehiculos
     */
    close(checkbox) {
      this.isSure = false
      this.checkbox = checkbox
      if (!this.checkbox) {
        this.dialog = false
        this.isEditing = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.modelos.indexOf(item)
      confirm('Esta seguro que desea eliminar este registro?') && this.grupos.splice(index, 1)
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar paises
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.modelos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Evalua el caracter para saber si es una letra
     */
    isLetter: function (e) {
      let char = String.fromCharCode(e.keyCode)
      if (/^[a-zA-Z\u00C0-\u00FF ]*$/.test(char)) return true
      else e.preventDefault()
    },

    /**
     * @description Obtiene un listado de todas las marcas
     */
    obtenerMarcas() {
      this.isLoading = true
      let payload = { params: '?campo1=marca&orden1=1' }
      this.$store
        .dispatch('brandVehicle/fetchBrandsVehicle', payload)
        .then((response) => {
          this.marcas = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Obtiene un listado de todas los modelos de vehiculos
     */
    obtenerModelos() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('modelVehicle/fetchModelsVehicle', payload)
        .then((response) => {
          this.modelos = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Guarda el contenido del formulario ya sea para un modelo nuevo o
     * la edicion de un modelo
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          modelo: this.editedItem.modelo,
          descripcion: this.editedItem.descripcion,
          marca: this.editedItem.marca,
          año: this.editedItem.año,
        }
        //let jwt = await getValidToken(this)
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('modelVehicle/editModelVehicle', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
          Object.assign(this.modelos[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('modelVehicle/addModelVehicle', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creo con éxito',
              })
              this.modelos.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
        }
        this.close((this.checkbox = true))
        this.changed = false
      }
    },
  },
}
</script>
